import {useState,useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

import {StyledMenu } from "./Menu.styles";
import { Border } from "../../components";
import { Link } from "react-router-dom";
import HelperFunctions from "../../../utils/HelperFunctions";
import { PWA_ROUTES } from "../../../constants/appConstants";
import {ManageScreen as events} from "../../../analytics/events";
import { getAyoba } from "../../../lib/microapp";
import { useNavigate } from "react-router-dom";
import { appState as appStateAction } from "../../../utils/redux/actions";


const helperFunction = new HelperFunctions();

const Menu = ({title, description, clickRoute}) => {

	const dispatch = useDispatch();
	const {profile} = useSelector((state) => state);
	
	const { logout, loginWithRedirect, isAuthenticated } = useAuth0();

	const {pendingItem} = useSelector((state) => state?.subscriptions);
	const {items} = useSelector((state) => state);
	const [showingPending, setShowPending] = useState(false);
	const [skipAuthentication, setSkipAuthentication] = useState(false);
	const { network } = useSelector((state) => state);
	const {  interactionOnClose } = appStateAction;


	const navigate = useNavigate();

	useEffect(() => {
    if (
      getAyoba() &&
      !isAuthenticated &&
      (clickRoute == PWA_ROUTES.PURCHASES ||
        clickRoute == PWA_ROUTES.SUBSCRIPTIONS)
    ) {
      setSkipAuthentication(false);
      return;
    }

    if (profile.data && profile?.data?.msisdn) {
      setSkipAuthentication(true);
      return;
    }

    if (
      clickRoute == PWA_ROUTES.TEST_AYOBA_VIDEO ||
      clickRoute == PWA_ROUTES.TEST_AYOBA_MSISDN ||
      clickRoute == PWA_ROUTES.TERMS_AND_CONDITIONS ||
      clickRoute == PWA_ROUTES.FAQ ||
      clickRoute == PWA_ROUTES.PRIVACY_NOTICE ||
	  clickRoute == PWA_ROUTES.LANGUAGES ||
	  clickRoute == PWA_ROUTES.HOME ||
      clickRoute == null
    ) {
      setSkipAuthentication(true);
    }
  }, [isAuthenticated, profile]);

	useEffect(() => {

		let canShowPending = false;
		helperFunction.subsStatusFilterUtil(items?.items, pendingItem?.subscriptionId, resp=>{
			canShowPending = resp == "Pending";
		});

		setShowPending(canShowPending);
	  }, [items?.items]);

	useEffect(()=>{
		console.log("clickroute",clickRoute);
		// dispatch(interactionOnClose(false));

		if(clickRoute==="#"  && localStorage.getItem("mtnlite") === true){
			localStorage.clear();
			navigate(PWA_ROUTES.LOGOUT);
		}
		if(clickRoute==="#"  && localStorage.getItem("mtnlite") === null){
			handleAuth0LogOut;
			return;
		}
	}, []);

	const handleAuth0LogOut = () =>{
    dispatch({
      type: "ANALYTICS/TRACK_EVENT",
      event: events.logout(
		network?.call?.headers?.isoRegion,
		network?.call?.headers.environment,
	  ),
    });
    // localStorage.clear();
    logout({ returnTo: `${window.location.origin}/logout` });
  };

	const handleAuth0Login = () =>{
		localStorage.setItem("state", JSON.stringify({"route":`${clickRoute}`, "extra":undefined}));
		loginWithRedirect({redirectUri:`${window.location.origin}/auth/callback`});
	};

	return (
		<>
			<StyledMenu>
				<Link onClick={clickRoute == "home"? dispatch(interactionOnClose(false)): clickRoute=="#"?handleAuth0LogOut:(!skipAuthentication?handleAuth0Login:null)} to={clickRoute=="#"?"":(skipAuthentication?clickRoute:"")}>
					<div className="menu-item">
						<div className="menu-details">
							<div className="title">{title}</div>
							<span className="description">{description}</span>
						</div>
					
						<div className="icon menu-icon">
						
							{title === "subscription" && showingPending &&
						<div style = {{paddingTop:"2px", marginLeft: -20, marginTop: 5, flex: 1, alignItems: "center", alignContent: "center", justifyContent:"center", textAlign:"center", height: "15px", width: "15px", borderRadius: "10px", backgroundColor:"#DC6428", color: "white", fontSize: "8px"}}>1</div>
							}
					
						</div>
					</div>
					<Border />
				</Link>

			</StyledMenu>


		</>
	);
};

export default Menu;
