// import React, {useEffect} from "react";
import styled from "styled-components";
import loader from "../../../assets/images/loader.gif";
import { Modal } from "../Modal";
import { ModalText } from "../ModalText";
import { ModalButtons } from "../ModalButtons";

const Loader = styled.div`
display: flex;
padding: 14px;
justify-content: center;
`;

const ProgressLoader = ({timedOut, altMessage, showMessageModal, title, errorMessage, shouldClose, width, height}) => {

	if(showMessageModal) return <>
		<Modal
			onClose={() => {}}
			show={showMessageModal}
			title={title}>
			<ModalText firstSentence={errorMessage}/>
			<ModalButtons
				onClose={()=>shouldClose()}
				okButtonText={"Close"}
			/>
		</Modal>
	</>;

	if(timedOut) return null;

	return (
		<Loader>
			<img src={loader} alt={altMessage} width={width} height={height} />
		</Loader>
	);
};

export default ProgressLoader;